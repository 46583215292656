import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import styled from '@emotion/styled';
import Send from '@mui/icons-material/Send';

import LinkWithIcon from '../common/LinkWithIcon';

const SendIcon = styled(Send)`
  font-size: 0.8em;
`;

const ContactSection = () => {
  return (
    <Container sx={{ textAlign: 'center' }} id="contact">
      <Box py={15}>
        <Typography variant="h4">Interested in working together?</Typography>
        <Typography mt={2} mb={1}>
          With an unwavering commitment to continuous improvement and a
          versatile skill set spanning various technologies, I'm ready to tackle
          any tech challenge you throw my way. Let's collaborate and bring your
          innovative ideas to life!
        </Typography>
        <LinkWithIcon
          href="mailto:joseeguigurenp@gmail.com"
          text="joseeguigurenp@gmail.com"
          icon={<SendIcon />}
        />
      </Box>
    </Container>
  );
};

export default ContactSection;

import React from 'react';
import { Icon, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { colors } from '../../config/colors';

const StyledLink = styled(Typography)`
  display: inline-flex;
  align-items: center;

  margin-right: 8px;
  color: inherit;
  text-decoration: none;
  padding-bottom: 4px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${colors.accent};
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: scaleX(1);
  }
`;

const LinkWithIcon = ({ href, text, icon, ...props }) => {
  return (
    <StyledLink href={href} component="a" {...props}>
      <span>{text}</span>
      <Icon sx={{ marginLeft: '5px', paddingBottom: '10px' }}>{icon}</Icon>
    </StyledLink>
  );
};

export default LinkWithIcon;

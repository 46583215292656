import React from 'react';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const StyledLink = styled(Typography)`
  text-decoration: none;
  color: inherit;

  &:visited {
    color: inherit;
  }
`;

const ScrollButton = ({ targetPage, scrollTo, label }) => {
  const isSamePage = window.location.pathname === targetPage;

  const handleClick = () => {
    if (scrollTo && isSamePage) {
      const element = document.getElementById(scrollTo);
      if (element) {
        window.scrollTo({
          top: element.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  const getUrlPath = () => {
    if (!isSamePage) {
      const anchorTarget = scrollTo ? `#${scrollTo}` : '';
      return `${targetPage}${anchorTarget}`;
    } else {
      return null;
    }
  };

  return (
    <StyledLink
      variant="button"
      component="a"
      href={getUrlPath()}
      onClick={handleClick}
    >
      {label}
    </StyledLink>
  );
};

export default ScrollButton;

import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from '../../config/colors';
import ScrollButton from '../common/ScrollButton';

const NavigationHeader = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isTop, setIsTop] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsTop(window.scrollY <= 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <AppBar
      color={isTop ? 'default' : 'secondary'}
      elevation={isTop ? 0 : 4}
      sx={{
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
        backgroundColor: isTop ? 'transparent' : colors.secondary,
        // boxShadow: isTop ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Container>
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            JE
          </Typography>
          {isSmallScreen ? (
            <>
              <IconButton
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                edge="end"
                sx={{ ml: 1 }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={closeDrawer}
                PaperProps={{ sx: { width: '50%' } }}
              >
                <List>
                  <ListItem button onClick={closeDrawer}>
                    <ScrollButton targetPage="/" label="Home" />
                  </ListItem>
                  <ListItem button onClick={closeDrawer}>
                    <ScrollButton
                      targetPage="/"
                      scrollTo="experience"
                      label="Projects"
                    />
                  </ListItem>
                  <ListItem button onClick={closeDrawer}>
                    <ScrollButton
                      targetPage="/"
                      scrollTo="contact"
                      label="Contact"
                    />
                  </ListItem>
                </List>
              </Drawer>
            </>
          ) : (
            <>
              <Button color="inherit">
                <ScrollButton targetPage="/" label="Home" />
              </Button>

              <Button color="inherit">
                <ScrollButton
                  targetPage="/"
                  scrollTo="experience"
                  label="Projects"
                />
              </Button>

              <Button color="inherit">
                <ScrollButton
                  targetPage="/"
                  scrollTo="contact"
                  label="Contact"
                />
              </Button>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavigationHeader;

import React from 'react';
import { Avatar, Box, Container, Typography } from '@mui/material';
import styled from '@emotion/styled';
import profileImage from '../../assets/images/profile_image.jpg';

const HeaderContent = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 90px 10% 60px 10%;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const AvatarImage = styled(Avatar)`
  width: 220px;
  height: 220px;

  @media (max-width: 600px) {
    width: 180px;
    height: 180px;
  }
`;

const AvatarContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;

  @media (max-width: 600px) {
    margin-right: 0;
  }
`;

const InfoContent = styled(Box)`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
`;

const Header = () => {
  return (
    <Container>
      <HeaderContent>
        <AvatarContainer item xs={4}>
          <AvatarImage src={profileImage} />
        </AvatarContainer>
        <InfoContent>
          <Typography variant="h3">José Eguiguren</Typography>
          <Typography variant="subtitle2" fontSize={20} mt={1}>
            Software Developer
          </Typography>
          {/* <Typography>
            Placeholder once I come up with a description...
          </Typography> */}
        </InfoContent>
      </HeaderContent>
    </Container>
  );
};

export default Header;

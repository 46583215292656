import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import SkillsGroup from './SkillsGroup';
import skills from '../../content/skills.json';

const sections = [
  {
    id: 1,
    name: 'Programming Languages',
    skills: skills.programming_languages,
  },
  {
    id: 2,
    name: 'Frameworks & Libraries',
    skills: skills.libraries_frameworks,
  },
  {
    id: 3,
    name: 'Tools & Platforms',
    skills: skills.tools_platforms,
  },
];

const SkillsSection = () => {
  return (
    <Container>
      <Typography variant="h3" mb={3} mt={6} textAlign="center">
        Skills
      </Typography>
      <Grid container spacing={2}>
        {sections.map((section) => (
          <Grid item xs={12} sm={6} md={4} key={section.id}>
            <SkillsGroup name={section.name} skills={section.skills} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SkillsSection;

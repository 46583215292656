import React from 'react';
import styled from '@emotion/styled';
import { Box, ImageList, ImageListItem, Typography } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import useViewport from '../../hooks/useViewport';
import SkillsGroup from '../skills/SkillsGroup';
import LinkWithIcon from '../common/LinkWithIcon';

const Title = styled(Typography)`
  word-wrap: break-word;
`;

const Links = styled(Box)`
  display: flex;
`;

const StyledLink = styled(LinkWithIcon)`
  margin-right: 30px;
  font-weight: 500;
`;

const ProjectContent = ({ project }) => {
  const { width } = useViewport();
  const breakpoint = 620;

  const imageCols =
    width < breakpoint
      ? project.images_config.cols_sm
      : project.images_config.cols;

  return (
    <Box pt={7}>
      <Title variant="h2" fontWeight={400} mb={3}>
        {project.name}
      </Title>

      {project.skills && <SkillsGroup skills={project.skills} />}
      {project.external_links && (
        <Links mt={4}>
          {project.external_links.map((link, i) => (
            <StyledLink
              key={`link_${i}`}
              sx={{ fontSize: '1.4em' }}
              href={link.url}
              target="_blank"
              text={link.name}
              icon={<LaunchIcon fontSize="small" />}
            />
          ))}
        </Links>
      )}
      {project.description && (
        <Typography mt={3} mb={5}>
          {project.description}
        </Typography>
      )}
      {project.images && (
        <ImageList variant="masonry" cols={imageCols} gap={8}>
          {project.images.map((image, i) => (
            <ImageListItem key={image}>
              <img src={image} alt={`${project.name} #${i}`} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
};

export default ProjectContent;

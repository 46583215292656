import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { logEvent } from 'firebase/analytics';

import { analytics } from '../firebase/firebase';

import Header from '../components/header/Header';
import SkillsSection from '../components/skills/SkillsSection';
import ResumeLink from '../components/resume/ResumeLink';
import ExperienceSection from '../components/experience/ExperienceSection';
import ContactSection from '../components/contact/ContactSection';
import Footer from '../components/footer/Footer';
import AboutMe from '../components/header/AboutMe';
import NavigationHeader from '../components/navigation/NavigationHeader';

const Home = () => {
  useEffect(() => {
    logEvent(analytics, 'viewed_home');

    const onPageLoad = () => {
      const idTarget = getTargetIdFromURL();
      const scrollTarget = document.getElementById(idTarget);
      if (scrollTarget) {
        window.scrollTo({
          top: document.getElementById(idTarget).offsetTop,
          behavior: 'smooth',
        });
      }
    };

    if (document.readyState === 'complete') {
      setTimeout(onPageLoad, 100);
      // onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const getTargetIdFromURL = () => {
    const url = window.location.href;
    const hashIndex = url.indexOf('#');

    if (hashIndex !== -1 && hashIndex < url.length - 1) {
      const idTarget = url.substring(hashIndex + 1);
      return idTarget;
    }

    return null;
  };

  return (
    <Box>
      <NavigationHeader />
      <Header />
      <AboutMe />
      <SkillsSection />
      <ResumeLink />
      <ExperienceSection />
      <ContactSection />
      <Footer />
    </Box>
  );
};

export default Home;

import React from 'react';
import { IconButton } from '@mui/material';
import { LinkedIn, GitHub, Mail } from '@mui/icons-material';
import styled from '@emotion/styled';

import { ReactComponent as CustomIcon } from '../../assets/treehouselogo.svg';
import { colors } from '../../config/colors';

const SocialMediaLinksContainer = styled.div`
  display: inline-flex;
  gap: 10px;
`;

const TreeHouseIcon = styled(CustomIcon)`
  width: 24px;
  height: 24px;
`;

const SocialMediaLink = ({ icon, href }) => {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <IconButton sx={{ color: colors.accent }}>{icon}</IconButton>
    </a>
  );
};

const SocialMediaLinks = () => {
  return (
    <SocialMediaLinksContainer>
      <SocialMediaLink
        icon={<LinkedIn />}
        href="https://www.linkedin.com/in/jose-eguiguren"
      />
      <SocialMediaLink icon={<GitHub />} href="https://github.com/JamesJose7" />
      <SocialMediaLink icon={<Mail />} href="mailto:joseeguigurenp@gmail.com" />
      <SocialMediaLink
        icon={<TreeHouseIcon fill={colors.accent} />}
        href="https://teamtreehouse.com/profiles/joseeduardo"
      />
    </SocialMediaLinksContainer>
  );
};

export default SocialMediaLinks;

import styled from '@emotion/styled';
import React from 'react';

const PDFViewerContainer = styled.div`
  height: 100vh;
`;

// TODO: Make PDF viewer mobile compatible
const PDFViewer = () => {
  return (
    <PDFViewerContainer>
      <iframe
        src="/pdfs/resume.pdf"
        width="100%"
        height="100%"
        title="PDF Viewer"
      />
    </PDFViewerContainer>
  );
};

export default PDFViewer;

import styled from '@emotion/styled';
import React from 'react';
import SkillPill from './SkillPill';
import { Box, Typography } from '@mui/material';

const SkillsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SkillsGroup = ({ name, skills }) => {
  return (
    <Box>
      {name && (
        <Typography variant="h5" fontSize="1.4em" mb={2}>
          {name}
        </Typography>
      )}
      <SkillsList>
        {skills.map((skill, i) => (
          <SkillPill key={`skill_${i}`} label={skill} />
        ))}
      </SkillsList>
    </Box>
  );
};

export default SkillsGroup;

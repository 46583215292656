import { Box, Paper, Typography, alpha } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import routes from '../../config/routes';
import { colors } from '../../config/colors';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-color: ${alpha(colors.primary, 0.5)};
`;

const Image = styled.img`
  width: 100%;
  height: 300px;
  object-fit: contain;
`;

// TODO: Fix broken react router links
const ProjectCard = ({ project }) => {
  return (
    <StyledLink
      onClick={() => {
        window.location = routes.PROJECT_PAGE.withParams(project.id);
      }}
    >
      <Paper elevation={2} sx={{ overflow: 'hidden' }}>
        <ImageContainer>
          <Image
            src={project.image_cover || project.images[0]}
            alt="Project cover"
          />
        </ImageContainer>
        <Box px={3} py={2}>
          <Typography variant="subtitle1" fontWeight={500} fontSize="1.5em">
            {project.name}
          </Typography>
        </Box>
      </Paper>
    </StyledLink>
  );
};

export default ProjectCard;

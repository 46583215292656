import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';

import { theme } from './config/theme';
import Home from './pages/Home';
import PDFViewer from './components/common/PDFViewer';
import routes from './config/routes';
import Project from './pages/Project';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path={routes.HOME} component={Home} />
          <Route exact path={routes.RESUME} component={PDFViewer} />
          <Route exact path={routes.PROJECT_PAGE.URL} component={Project} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;

import styled from '@emotion/styled';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../config/colors';
import ProjectCard from './ProjectCard';

import projects from '../../content/projects.json';

const Background = styled(Box)`
  background-color: ${colors.secondary};
  margin-top: 45px;
  padding: 60px 0;
  color: white;
`;

const ExperienceSection = () => {
  return (
    <Background id="experience">
      <Container>
        <Typography variant="h3" textAlign="center">
          Work Experience
        </Typography>
        <Typography mt={1} textAlign="center">
          These are projects I have worked on during my professional career
        </Typography>
        <Grid container spacing={4} mt={1}>
          {projects.work_projects.map((project) => (
            <Grid item lg={4} sm={6} xs={12} key={project.id}>
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>

        <Typography variant="h4" mt={4} textAlign="center">
          Personal Projects
        </Typography>
        <Typography mt={1} textAlign="center">
          Some of my personal projects I've worked on for fun and learning
        </Typography>
        <Grid container spacing={4} mt={1}>
          {projects.personal_projects.map((project) => (
            <Grid item lg={4} sm={6} xs={12} key={project.id}>
              <ProjectCard project={project} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Background>
  );
};

export default ExperienceSection;

import { Container, Typography } from '@mui/material';
import React from 'react';

const AboutMe = () => {
  return (
    <Container>
      <Typography>
        I am a highly skilled software developer with a passion for crafting
        effective solutions. With a strong background in Mobile and Web
        application development, I have successfully delivered projects for
        diverse clients, ranging from renowned organizations to freelance
        endeavors. My technical expertise encompasses a wide range of
        technologies, including Android, React, React Native, Firebase, and
        Spring Boot. Complemented by my proficiency in utilizing modern
        development frameworks and following best practices, I consistently
        deliver robust, user-centric applications. Known for my collaborative
        approach and meticulous attention to detail, I thrive in team
        environments, driving projects to success through effective
        communication and problem-solving. With a constant drive for growth and
        a keen eye for emerging technologies, I am always ready to tackle new
        challenges and create impactful software solutions.
      </Typography>
    </Container>
  );
};

export default AboutMe;

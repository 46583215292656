import React from 'react';
import styled from '@emotion/styled';
import { Box, Container, Typography } from '@mui/material';

import { colors } from '../../config/colors';
import SocialMediaLinks from '../common/SocialMediaLinks';

// Always learning / Forever improving

const Background = styled(Box)`
  background-color: ${colors.secondary};
  margin-top: 45px;
  color: white;
  text-align: center;
`;

const Footer = () => {
  return (
    <Background>
      <Container>
        <Box py={8}>
          <Typography fontWeight={300}>Always learning</Typography>
          <Typography mb={2} fontWeight={300}>
            Forever improving
          </Typography>
          <SocialMediaLinks />
          <Typography mt={2} fontWeight={300}>
            Website handcrafted by me
          </Typography>
        </Box>
      </Container>
    </Background>
  );
};

export default Footer;

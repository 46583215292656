import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { colors } from './colors';

let theme = createTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
  },
  typography: {
    fontFamily: 'Work Sans, sans-serif',
    fontSize: 15,
  },
});

theme = responsiveFontSizes(theme);

export { theme };

import projects from '../content/projects.json';

export const findProjectById = (projectId) => {
  const workProject = projects.work_projects.find(
    (project) => project.id.toString() === projectId
  );
  const personalProject = projects.personal_projects.find(
    (project) => project.id.toString() === projectId
  );

  return workProject || personalProject || undefined;
};

import styled from '@emotion/styled';
import React from 'react';
import { Typography } from '@mui/material';
import { colors } from '../../config/colors';

const Pill = styled(Typography)`
  padding: 6px 12px;
  margin-top: 0px;
  margin-bottom: 8px;
  margin-right: 8px;
  border-radius: 20px;
  font-size: 1em;
  color: white;
  background-color: ${colors.primary};
`;

const SkillPill = ({ label }) => {
  return <Pill fontWeight={300}>{label}</Pill>;
};

export default SkillPill;

import React from 'react';
import styled from '@emotion/styled';
import { Box, Container } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import LinkWithIcon from '../common/LinkWithIcon';
// import routes from '../../config/routes';

const Content = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResumeLink = () => {
  return (
    <Container>
      <Content mt={6} sx={{ textAlign: 'center' }}>
        <LinkWithIcon
          sx={{ fontSize: '1.4em' }}
          href={'/pdfs/resume.pdf'}
          target="_blank"
          text="View Full Resume"
          icon={<LaunchIcon fontSize="small" />}
        />
      </Content>
    </Container>
  );
};

export default ResumeLink;

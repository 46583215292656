import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import styled from '@emotion/styled';
import { logEvent } from 'firebase/analytics';

import NavigationHeader from '../components/navigation/NavigationHeader';
import ProjectContent from '../components/projects/ProjectContent';
import Footer from '../components/footer/Footer';

import { findProjectById } from '../utils/projectsUtils';
import { analytics } from '../firebase/firebase';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const ContentContainer = styled(Container)`
  flex-grow: 1;
  overflow-y: auto;
`;

const Project = ({ match }) => {
  const [project, setProject] = useState();
  const projectId = match.params.projectId;

  useEffect(() => {
    if (projectId) {
      const matchedProject = findProjectById(projectId);
      // TODO: Redirect to 404 if no project is found
      if (matchedProject) {
        logEvent(analytics, 'project_opened', {
          project_id: projectId,
        });
      }
      setProject(matchedProject);
    }
  }, [projectId]);

  return (
    <Wrapper sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <NavigationHeader />
      <MainContent>
        {project && (
          <ContentContainer>
            <ProjectContent project={project} />
          </ContentContainer>
        )}
      </MainContent>
      <Footer />
    </Wrapper>
  );
};

export default Project;

const routes = {
  HOME: '/',
  RESUME: '/resume',
  PROJECT_PAGE: {
    URL: '/project/:projectId',
    withParams: (projectId) => `/project/${projectId}`,
  },
};

export default routes;
